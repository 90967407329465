import { toast } from 'react-toastify';
import { apiCaller } from '../../middleware/api-caller';
import { ChoseTheme, SelectedLanguage } from "store/actions/common";
import { errorMessages, successMessage } from 'utils/helpers';

export const ChangeTheme = (data) => {
  return (dispatch) => {
    dispatch(ChoseTheme(data));
  }
};

export const ChangeLanguage = (data) => {
  return (dispatch) => {
    dispatch(SelectedLanguage(data));
  }
};

export const getData = async (method, url) => {

  const myJson = await apiCaller({ method, url });

  if (
    myJson &&
    (myJson?.data?.statusCode === 200) | (myJson?.data?.statusCode === 201)
  ) {
    const { data } = myJson;
    if (data?.data) {
      return data?.data;
    }
  } else {
    const message =
      (myJson?.data?.message || myJson?.response?.data?.message) ??
      "Something went wrong";
    //   toast.error(errorMessages(message))
  }
};

export const specialGetData = async (method, url) => {

  const myJson = await apiCaller({ method, url });

  if (
    myJson &&
    (myJson?.data?.statusCode === 200) | (myJson?.data?.statusCode === 201)
  ) {
    const { data } = myJson;
    if (data?.data) {
      return {
        data: data?.data,
        total_interceptions: data?.total_interceptions,
        total_trials: data?.total_trials,
        sales: data?.sales,
        sales_amount: data?.sales_amount,
      };
    }
  } else {
    const message =
      (myJson?.data?.message || myJson?.response?.data?.message) ??
      "Something went wrong";
    //   toast.error(errorMessages(message))
  }
};

export const getDataJson = async (method, url) => {

  const myJson = await apiCaller({ method, url });

  if (
    myJson &&
    (myJson?.data?.statusCode === 200) | (myJson?.data?.statusCode === 201)
  ) {
    const data = myJson;
    if (data?.data) {
      return data?.data;
    }
  } else {
    const message =
      (myJson?.data?.message || myJson?.response?.data?.message) ??
      "Something went wrong";
    //   toast.error(errorMessages(message))
  }
};