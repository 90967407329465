import * as ActionType from "../actions/Types";
import StoreState from "./initialState";
import cloneDeep from "lodash/cloneDeep";

export default function FinanceFiltersReducer(state = StoreState.FinanceFilters, action) {
  switch (action.type) {
    case ActionType.UPDATEFINANCEFILTERS:
      const s = cloneDeep(state);
      s.organization = action?.data?.organization;
      s.activity = action?.data?.activity;
      s.program = action?.data?.program;
      s.form = action?.data?.form;
      return s;

    default:
      return state;
  }
}
