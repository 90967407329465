export const FeaturePermissions = {
    WebToIPCall: "WIPFM_91"
}



export const Permissions = {
    USER_MANAGEMENT: 'UM_1',
    USER_MANAGEMENT_SCREEN: 'UM_UMS_2',
    ADD_NEW_USER: 'UM_UMS_ANUB_ANUB_3',
    ADD_USER_IN_BULK_: 'UM_UMS_IDB_AUIBB_4',
    DELETE_USERS_: 'UM_UMS_DUB_DUB_5',
    STATUS_UPDATE_USER: 'UM_UMS_SUU_SUU_6',
    GROUP_MANAGEMENT_SCREEN: 'UM_GMS_7',
    CREATE_NEW_GROUP_: 'UM_GMS_CNGB_CNGB_8',
    ASSIGN_GROUP_: 'UM_GMS_AGB_AGB_9',
    STATUS_UPDATE_GROUP: 'UM_GMS_SUG_SUG_10',
    ASSIGN_BRANCH_: 'UM_GMS_ABB_ABB_11',
    ROLE_MANAGEMENT_SCREEN: 'UM_RMS_12',
    CREATE_NEW_ROLE: 'UM_RMS_CNRB_CNRB_13',
    ASSIGN_ROLE: 'UM_RMS_ARB_ARB_14',
    STATUS_UPDATE_ROLES: 'UM_RMS_SUR_SUR_15',
    PERMISSION_MANAGEMENT_SCREEN: 'UM_PMS_16',
    ASSIGN_PERMISSION_BUTTON: 'UM_PMS_APB_APB_17',
    ORGANIZATION_MANAGEMENT: 'OM_18',
    ORGANIZATION_MANAGEMENT_SCREEN: 'OM_OMS_19',
    CREATE_NEW_ORGANIZATION: 'OM_OMS_CNOB_CNOB_20',
    STATUS_UPDATE_ORGANIZATION: 'OM_OMS_SUO_SUOB_21',
    SITE_MANAGEMENT_SCREEN: 'OM_SMS_22',
    CREATE_NEW_SITE: 'OM_SMS_CNSB_CNSB_23',
    STATUS_UPDATE_SITE: 'OM_SMS_SUS_SUSB_24',
    BRANCH_MANAGEMENT_SCREEN: 'OM_BMS_25',
    CREATE_NEW_BRANCH: 'OM_BMS_CNBB_CNBB_26',
    STATUS_UPDATE_BRANCH: 'OM_BMS_SUB_SUBB_27',
    CATEGORY_MANAGEMENT_SCREEN: 'OM_CMS_28',
    CREATE_NEW_CATEGORY: 'OM_CMS_CNCB_CNCB_29',
    STATUS_UPDATE_CATEGORY: 'OM_CMS_SUC_SUCB_30',
    SUB_CATEGORY_MANAGEMENT_SCREEN: 'OM_SCMS_31',
    CREATE_NEW_SUB_CATEGORY: 'OM_SCMS_CNSCB_CNSCB_32',
    STATUS_UPDATE_SUB_CATEGORY: 'OM_SCMS_SUSC_SUSCB_33',
    PRODUCT_MANAGEMENT_SCREEN: 'OM_SCPMG_34',
    CREATE_NEW_PRODUCT: 'OM_SCPMS_CNSCPB_CNSCPB_35',
    STATUS_UPDATE_PRODUCT: 'OM_SCPMS_SUSCP_SUSCPB_36',
    POP_MANAGEMENT: 'PM_37',
    POP_MANAGEMENT_SCREEN: 'PM_POMS_38',
    CREATE_NEW_POP: 'PM_POMS_CNPOB_CNPOB_39',
    STATUS_UPDATE_POP: 'PM_POMS_SUPO_SUPOB_40',
    POP_TYPE_MANAGEMENT_SCREEN: 'PM_POTMS_41',
    CREATE_NEW_POP_TYPE: 'PM_POTMS_CNPOTB_CNPOTB_42',
    STATUS_UPDATE_POP_TYPE: 'PM_POTMS_SUPOT_SUPOTB_43',
    REGION_MANAGEMENT_SCREEN: 'PM_REMS_44',
    CREATE_NEW_REGION: 'PM_REMS_CNREB_CNREB_45',
    STATUS_UPDATE_REGION: 'PM_REMS_SURE_SUREB_46',
    ZONE_MANAGEMENT_SCREEN: 'PM_ZMS_47',
    CREATE_NEW_ZONE: 'PM_ZMS_CNZB_CNZB_48',
    STATUS_UPDATE_ZONE: 'PM_ZMS_SUZ_SUZB_49',
    TERRITORY_MANAGEMENT_SCREEN: 'PM_TMS_50',
    CREATE_NEW_TERRITORY: 'PM_TMS_CNTB_CNTB_51',
    STATUS_UPDATE_TERRITORY: 'PM_TMS_SUT_SUTB_52',
    ACTIVITY_MANAGEMENT: 'AM_53',
    PROGRAM_MANAGEMENT_SCREEN: 'AM_PGMS_54',
    CREATE_NEW_PROGRAM: 'AM_PGMS_CNPGB_CNPGB_55',
    STATUS_UPDATE_PROGRAM: 'AM_PGMS_SUPG_SUPGB_56',
    ACTIVITY_MANAGEMENT_SCREEN: 'AM_AMS_57',
    CREATE_NEW_ACTIVITY: 'AM_AMS_CNAB_CNAB_58',
    STATUS_UPDATE_ACTIVITY: 'AM_AMS_SUA_SUAB_59',
    FORM_MANAGEMENT_SCREEN: 'AM_FMS_60',
    CREATE_NEW_FORM: 'AM_FMS_CNFB_CNFB_61',
    GEO_FENCE_MANAGEMENT: 'GM_62',
    GEO_FENCE_MANAGEMENT_SCREEN: 'GM_GFMS_63',
    CREATE_NEW_GEO_FENCE: 'GM_GFMS_CNGFB_CNGFB_64',
    STATUS_UPDATE_GEO_FENCE: 'GM_GFMS_SUGF_SUGFB_65',
    REPORTING_MANAGEMENT: 'RM_66',
    REPORTING__MANAGEMENT_SCREEN: 'RM_REPMS_67',
    RECORD_RESPONSE_COLUMNS: 'RM_REPMS_RRCB_RRCB_68',
    VIEW_RECORD_RESPONSE: 'RM_REPMS_VRRB_VRRB_69',
    EDIT_RECORD_RESPONSE: 'RM_REPMS_ERRB_ERRB_70',
    MONITORING_MANAGEMENT: 'MM_71',
    MONITORING_MANAGEMENT_SCREEN: 'MM_MMS_72',
    ATTENDANCE_MANAGEMENT_SCREEN: 'MM_ATMS_73',
    GAME_MANAGEMENT: 'GGM_84',
    GAME_MANAGEMENT_SCREEN: 'GGM_GGMS_85',
    CREATE_NEW_GAME: 'GGM_GGMS_CNGGB_CNGGB_86',
    ADD_GIFT: 'GGM_GGMS_AGGB_AGGB_87',
    INSTORE_DS: 'INSDM_98',
    DDS_DS: 'DDSDM_97',
    SUP_DS: "SUPDM_99",
    FINANCE_MODULE: "FINDM_100",
    FINANCE_ORGANIZATION_SCREEN: "FINDM_ORGVMS_101",
    FINANCE_ORGANIZATION_SHOW: "FINDM_ORGVMS_SDB_SDB_102",
    FINANCE_ORGANIZATION_PDF: "FINDM_ORGVMS_DPSDB_DPSDB_103",
    FINANCE_AGENCY_SCREEN: "FINDM_AGVMS_104",
    FINANCE_AGENCY_SHOW: "FINDM_AGVMS_SDDB_SDDB_105",
    FINANCE_AGENCY_SEND: "FINDM_AGVMS_SPDMB_SPDMB_106",
    FINANCE_AGENCY_EDIT: "FINDM_AGVMS_EDMB_EDMB_107"
}