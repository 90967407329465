import { THEME, SELECTEDLANGUAGE, CHANGENAVIGATION } from "./Types";

export function ChoseTheme(data) {
  return {
    type: THEME,
    data,
  };
}

export function SelectedLanguage(data) {
  return {
    type: SELECTEDLANGUAGE,
    data,
  };
}

export function ChangeNavigation(data) {
  return {
    type: CHANGENAVIGATION,
    data,
  };
}

