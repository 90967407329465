export const validateForm = () => {
  const form = document.getElementsByClassName("needs-validation")[0];
  const elements = form.getElementsByClassName("field");
  let isValid = true;

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];
    if (element.required && !element.value.trim()) {
      element.classList.add("was-validated");
      isValid = false;
    } else if (!element.checkValidity()) {
      element.classList.add("was-validated");
      isValid = false;
    } else {
      element.classList.remove("was-validated");
    }
  }

  return isValid;
};

export const unCheckForm = () => {
  var form = document.getElementsByClassName("needs-validation")[0];
  var elements = form.getElementsByClassName("inputfield");
  for (var i = 0; i < elements.length; i++) {
    elements[i].classList.remove("was-validated");
  }
};

export const validateField = () => {
  var elements = document.getElementsByClassName("field");
  let isValid = true;
  if (elements) {
    for (var i = 0; i < elements.length; i++) {
      if (elements[i].checkValidity()) {
        elements[i].classList.remove("was-validated");
        isValid = false;
      }
    }
  }
  return isValid;
};

export const customFormValidation = (data, errorMessages) => {
  var form = document.getElementById("myForm");
  var elements = form.getElementsByClassName("field");
  let isValid = true;
  for (var i = 0; i < elements.length; i++) {
    if (!elements[i].checkValidity()) {
      elements[i].classList.add("was-validated");
      isValid = false;
    }
  }
  return isValid;
};
