import * as ActionType from "../actions/Types";
import StoreState from "./initialState";
import cloneDeep from "lodash/cloneDeep";

export default function AnnouncementReducer(state = StoreState.announcement, action) {
  switch (action.type) {
    case ActionType.GETALLANNOUNCEMENTLISTING:
      var s = cloneDeep(state);
      s.listing = action?.data?.data;
      return s;
    default:
      return state;
  }
}
