import { ActivityTypeEnum } from "utils/constants/DDsDashboard";
import { getDynamicDates } from "utils/helpers/dashboard";

var StoreState = {
  auth: {
    accessToken: {},
    refreshToken: "",
    user: [],
    permissions: {},
    userTypes: [],
  },
  common: {
    Theme: "",
    Language: "",
    NavItems: [],
    city: [],
    geoFence: [],
    pop: []
  },
  globalFilters: {
    organization: [],
    dateRange: [],
    dateRangeNew: [],
    program: [],
    activity: [],
    form: [],
    fieldStaff: [],
    activityType: ActivityTypeEnum.DTD,
    city: [],
    fieldStaffGroup: [],
    geoFence: [],
    pop: [],
    branch: [],
    site: [],
    rawData: [],
    filteredData: [],
    organizations: [],
    programs: [],
    cities: [],
    FilterDate: [],
    activitys: [],
    forms: [],
    fieldStaffs: [],
    fieldStaffGroups: [],
    geoFences: [],
    pops: [],
    branches: [],
    sites: [],
  },
  DDsFilters: {
    activityType: ActivityTypeEnum.DTD,
    organization: [],
    dateRange: getDynamicDates(),
    program: [],
    activity: [],
    form: [],
    city: [],
    pop: [],
    geoFence: [],
    Brand: [],
    Agency: [],
    fieldStaff: [],
    fieldStaffGroup: [],
    Pak: [],
    Sec: [],
  },
  FinanceFilters: {
    organization: "",
    program: "",
    activity: "",
    form: ""
  },
  apiCallStatus: {
    apiCallFor: "",
    statusCode: "",
    isLoading: false,
    isCompleted: true,
    isFailed: false,
    message: "",
    isStarted: [],
    isError: [],
  },
  userManagement: {
    getAllUsers: [],
    getAllUserStats: [],
    getAllOrganizations: [],
    getAllGroups: [],
    getAllPermissons: [],
    getAllPermissonsType: [],
    organizationByType: [],
    organizationDetails: [],
    locations: [],
    groupUsers: [],
    roleGroup: [],
    organizationTypes: [],
    sitiesData: [],
    sitieBranches: [],
    allUsersOfGroup: [],
    sitesData: [],
    branchesData: [],
    allPermissions: [],
  },
  popManagement: {
    getAllPOPTypes: [],
    getPOPTypes: [],
    getPOPRegions: [],
    getPOPZones: [],
    getPOPTerritories: [],
    popTypes: [],
    popTypeStats: {},
    location: [],
    organisation: [],
    regionStat: [],
    popBypopId: [],
  },

  categoryManagement: {
    getAllCategories: [],
    getAllSubCategories: [],
    getAllProducts: [],
  },

  activityManagement: {
    getAllPrograms: [],
    getProgramForms: [],
    activityFormData: [],
    activityForm: [],
    activitesInProgram: [],
  },
  apiCallStatus: {
    apiCallFor: "",
    statusCode: "",
    isLoading: false,
    isCompleted: true,
    isFailed: false,
    message: "",
    isStarted: [],
    isError: [],
  },
  monitoring: {
    staffAttendance: [],
  },
  game: {
    listing: [],
    giftListing: [],
  },
  announcement: {
    listing: []
  },
};
export default StoreState;
