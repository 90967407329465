import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import PropTypes from "prop-types";
import SidebarNavigation from "../UserManagementLayout/sideNavigationBar/Sidebar";
import { setCookie } from "services/session/cookies";
import { ChangeTheme, getData } from "services/api/common";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ENDPOINTS, REQUEST_TYPES } from "services/middleware/url";

export const UserManagementLayout = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;
  const [sideNavigationShow, setSideNavigationShow] = useState(false);
  const ThemeColor = useSelector((state) => state?.common?.Theme);
  const [data, setData] = useState(false);

  const changeThemeColor = (value) => {
    dispatch(ChangeTheme(value));
  };

  const permissionsFetch = async () => {
    const userPermissions =
      (await getData(REQUEST_TYPES.GET, `${ENDPOINTS.GET_USER_PERMISSIONS}`)) ??
      [];
    setCookie("permissions", JSON.stringify(userPermissions));
  }

  useEffect(() => {
    const savedData = localStorage.getItem("ThemeColor")
      ? localStorage.getItem("ThemeColor")
      : "";
    const themeColor = savedData === "light" || savedData === "" ? true : false;
    dispatch(ChangeTheme(themeColor));
    permissionsFetch()
  }, []);

  return (
    <>
      {/* {sideNavigationShow && (
        <SidebarNavigation
          sideNavigationShow={sideNavigationShow}
          setSideNavigationShow={setSideNavigationShow}
          changeThemeColor={changeThemeColor}
          ThemeColor={!ThemeColor}
        />
      )} */}
      <div className="flex bg-white ">
        <div>
          <div
            className={`fixed top-0 bottom-0 left-0 right-0 w-full  ${ThemeColor && pathname !== "/user-management-dashboard"
              ? "bg-for-light"
              : "bg-dark-theme"
              }`}
          ></div>
        </div>
        {pathname !== "/user-management-dashboard" ? (
          <Header
            changeThemeColor={changeThemeColor}
            ThemeColor={!ThemeColor}
            pathname={pathname}
          />
        ) : (
          <Header
            changeThemeColor={changeThemeColor}
            ThemeColor={true}
            pathname={pathname}
          />
        )}

        <div>
          <Sidebar
            sideNavigationShow={sideNavigationShow}
            setSideNavigationShow={setSideNavigationShow}
            ThemeColor={ThemeColor}
          />
        </div>

        <div className="mt-14 ml-[3.25rem] md:ml-16 w-full overflow-y-hidden h-[90vh]">{children}</div>
      </div>
    </>
  );
};

UserManagementLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
