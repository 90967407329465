import { ActivityTypeEnum } from "utils/constants/DDsDashboard";
import * as ActionType from "../actions/Types";
import StoreState from "./initialState";
import cloneDeep from "lodash/cloneDeep";
import { getDynamicDates } from "utils/helpers/dashboard";

export default function FiltersReducer(state = StoreState.globalFilters, action) {
  switch (action.type) {
    case ActionType.UPDATEFILTERS:
      const s = cloneDeep(state);
      s.organization = action?.data?.organization;
      s.dateRange = action?.data?.dateRange;
      s.dateRangeNew = action?.data?.dateRangeNew;
      s.branch = action?.data?.branch;
      s.site = action?.data?.site;
      s.activityType = action?.data?.activityType;
      s.FilterDate = action?.data?.FilterDate;
      s.city = action?.data?.city;
      s.program = action?.data?.program;
      s.activity = action?.data?.activity;
      s.form = action?.data?.form;
      s.fieldStaff = action?.data?.fieldStaff;
      s.fieldStaffGroup = action?.data?.fieldStaffGroup;
      s.geoFence = action?.data?.geoFence;
      s.pop = action?.data?.pop;
      return s;

    case ActionType.RESETFILTERS:
      const s1 = cloneDeep(state);
      s1.organization = "";
      s1.dateRange = [];
      s1.dateRangeNew = [];
      s1.branch = "";
      s1.site = "";
      s1.city = "";
      s1.FilterDate = getDynamicDates()
      s1.program = "";
      s1.activity = "";
      s1.form = "";
      s1.fieldStaff = "";
      s1.fieldStaffGroup = "";
      s1.geoFence = "";
      s1.pop = "";
      return s1;

    case ActionType.SETRAWDATA:
      const s2 = cloneDeep(state);
      s2.rawData = action?.data?.rawData;
      return s2;

    case ActionType.DROPDOWNFILTRATION:
      const s3 = cloneDeep(state);
      let filteredData = s3.rawData;

      const { organization, city, branch, site, program, activity, form, geoFence, pop, fieldStaffGroup, fieldStaff, filterby, filterMode } = action?.data
      if (filterMode === "multiple") {
        if (filteredData && organization && organization.length) {
          filteredData = filteredData.filter(item => organization.includes(item?.organizationId));
        }
        if (filteredData && city && city.length) {
          filteredData = filteredData.filter(item => city.includes(item?.city_id));
        }
        if (filteredData && program && program.length) {
          filteredData = filteredData.filter(item => program.includes(item?.programId));
        }
        if (filteredData && activity && activity.length) {
          filteredData = filteredData.filter(item => activity.includes(item?.activityId));
        }
        if (filteredData && form && form.length) {
          filteredData = filteredData.filter(item => form.includes(item?.id));
        }
        if (filteredData && geoFence && geoFence.length) {
          filteredData = filteredData.filter(item => geoFence.includes(item?.geofenceId));
        }
        if (filteredData && pop && pop.length) {
          filteredData = filteredData.filter(item => pop.includes(item?.popId));
        }

        if (filteredData && fieldStaffGroup && fieldStaffGroup.length) {
          filteredData = filteredData.filter(item => fieldStaffGroup.includes(item?.groupId));
        }
        if (filteredData && fieldStaff && fieldStaff.length) {
          filteredData = filteredData.filter(item => fieldStaff.includes(item?.userId));
        }
        if (filteredData && branch && branch.length) {
          filteredData = filteredData.filter(item => branch.includes(item?.branchId));
        }
        if (filteredData && site && site.length) {
          filteredData = filteredData.filter(item => site.includes(item?.siteId));
        }
      } else {
        if (filteredData && !Array.isArray(organization) && organization) {
          filteredData = filteredData.filter(item => item?.organizationId === organization);
        }
        if (filteredData && !Array.isArray(city) && city) {
          filteredData = filteredData.filter(item => item?.city_id === city);
        }
        if (filteredData && !Array.isArray(program) && program) {
          filteredData = filteredData.filter(item => item?.programId === program);
        }
        if (filteredData && !Array.isArray(activity) && activity) {
          filteredData = filteredData.filter(item => item?.activityId === activity);
        }
        if (filteredData && !Array.isArray(form) && form) {
          filteredData = filteredData.filter(item => item?.id === form);
        }
        if (filteredData && !Array.isArray(geoFence) && geoFence) {
          filteredData = filteredData.filter(item => item?.geofenceId === geoFence);
        }
        if (filteredData && !Array.isArray(pop) && pop) {
          filteredData = filteredData.filter(item => item?.popId === pop);
        }
        if (filteredData && !Array.isArray(fieldStaffGroup) && fieldStaffGroup) {
          filteredData = filteredData.filter(item => item?.groupId === fieldStaffGroup);
        }
        if (filteredData && !Array.isArray(fieldStaff) && fieldStaff) {
          filteredData = filteredData.filter(item => item?.userId === fieldStaff);
        }
        if (filteredData && !Array.isArray(branch) && branch) {
          filteredData = filteredData.filter(item => item?.branchId === branch);
        }
        if (filteredData && !Array.isArray(site) && site) {
          filteredData = filteredData.filter(item => item?.siteId === site);
        }
      }

      let organizations = [];
      let programs = [];
      let activitys = [];
      let forms = [];
      let fieldStaffs = [];
      let fieldStaffGroups = [];
      let geoFences = [];
      let cities = [];
      let pops = [];
      let sites = [];
      let branches = [];

      let organizationSet = new Set();
      let programSet = new Set();
      let activitySet = new Set();
      let formSet = new Set();
      let fieldStaffSet = new Set();
      let fieldStaffGroupSet = new Set();
      let geoFenceSet = new Set();
      let citySet = new Set();
      let popSet = new Set();
      let sitesSet = new Set();
      let branchesSet = new Set();

      filteredData && filteredData.forEach((item) => {
        if (!organizationSet.has(item?.organizationId)) {
          item?.organizationId && organizationSet.add(item?.organizationId);
          item?.name && item?.organizationId && organizations.push({ label: item?.name, value: item?.organizationId });
        }
        if (!programSet.has(item?.programId)) {
          item?.programId && programSet.add(item?.programId);
          item?.program_name && item?.programId && programs.push({ label: item?.program_name, value: item?.programId });
        }
        if (!citySet.has(item?.city_id)) {
          item?.city_id && citySet.add(item?.city_id);
          item?.city_name && item?.city_id && cities.push({ label: item?.city_name, value: item?.city_id });
        }
        if (!activitySet.has(item?.activityId)) {
          item?.activityId && activitySet.add(item?.activityId);
          item?.activity_name && item?.activityId && activitys.push({ label: item?.activity_name, value: item?.activityId });
        }
        if (!formSet.has(item?.id)) {
          item?.id && formSet.add(item?.id);
          item?.form_name && item?.id && forms.push({ label: item?.form_name, value: item?.id });
        }
        if (!fieldStaffSet.has(item?.userId)) {
          item?.userId && fieldStaffSet.add(item?.userId);
          item?.first_name && item?.last_name && item?.userId && fieldStaffs.push({ label: `${item?.first_name} ${item?.last_name}`, value: item?.userId });
        }
        if (!fieldStaffGroupSet.has(item?.groupId)) {
          item?.groupId && fieldStaffGroupSet.add(item?.groupId);
          item?.group_name && item?.groupId && fieldStaffGroups.push({ label: item?.group_name, value: item?.groupId });
        }
        if (!geoFenceSet.has(item?.geofenceId)) {
          item?.geofenceId && geoFenceSet.add(item?.geofenceId);
          item?.geofencing_name && item?.geofenceId && geoFences.push({ label: item?.geofencing_name, value: item?.geofenceId });
        }
        if (!popSet.has(item?.popId)) {
          item?.popId && popSet.add(item?.popId);
          item?.pop_name && item?.popId && pops.push({ label: item?.pop_name, value: item?.popId });
        }
        if (!sitesSet.has(item?.siteId)) {
          item?.siteId && sitesSet.add(item?.siteId);
          item?.site && item?.siteId && sites.push({ label: item?.site, value: item?.siteId });
        }
        if (!branchesSet.has(item?.branchId)) {
          item?.branchId && branchesSet.add(item?.branchId);
          item?.branch && item?.branchId && branches.push({ label: item?.branch, value: item?.branchId });
        }
      });

      s3.organizations = filterby === "organization" ? s3.organizations : organizations.filter(item => item !== null)
      s3.programs = filterby === "program" ? s3.programs : programs.filter(item => item !== null)
      s3.cities = filterby === "city" ? s3.cities : cities.filter(item => item !== null)
      s3.activitys = filterby === "activity" ? s3.activitys : activitys.filter(item => item !== null)
      s3.forms = filterby === "form" ? s3.forms : forms.filter(item => item !== null)
      s3.fieldStaffs = filterby === "fieldStaff" ? s3.fieldStaffs : fieldStaffs.filter(item => item !== null)
      s3.fieldStaffGroups = filterby === "fieldStaffGroup" ? s3.fieldStaffGroups : fieldStaffGroups.filter(item => item !== null)
      s3.geoFences = filterby === "geoFence" ? s3.geoFences : geoFences.filter(item => item !== null)
      s3.pops = filterby === "pop" ? s3.pops : pops.filter(item => item !== null)
      s3.sites = filterby === "site" ? s3.sites : sites.filter(item => item !== null)
      s3.branches = filterby === "branch" ? s3.branches : branches.filter(item => item !== null)

      return s3;

    default:
      return state;
  }
}
