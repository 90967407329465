
export const IsShownEnum = {
  Program_Management: 2,
  User_Management: 3,
  Organization_Management: 4,
  Pop_Type_Management: 5,
  Geo_Fence: 6,
  Reporting: 7,
  Speech_Checking: 8,
  Monitoring: 9,
  Games: 10,
  Feature_Management: 11,
  Finance_Management: 17,
  Announcement: 12,
  Dashboard: 15,
  translate_uploader: 16,
}
