import home from "assets/home.png";
import group from "assets/group.png";
import location from "assets/location.png";
import feature from "assets/feature-off.svg";
import feature_color from "assets/feature.svg";
import reporting from "assets/images/reporting.svg";
import eye_monitor from "assets/eye_monitor.png";
import profit from "assets/profit.png";
import eye_menu from "assets/eye_menu.svg";
import file_chart_line from "assets/file_chart_line.png";
import Group_management from "assets/Group_management.png";
import homes from "assets/Homes.svg";
import pop_management from "assets/pop_management.png";
import speaker from "assets/speaker.png";
import finance from "assets/finance.svg";
import game from "assets/game.png";
import { Permissions } from "./Permissions/features";

export const MainMenu = [
  {
    id: 1,
    label: "Home",
    link: "/main-dashboard",
    icon: `${homes}`,
    key: "A01",
    showKey: "1"
  },
  {
    id: 2,
    label: "Analytics",
    link: "/dds-dashboard",
    icon: `${profit}`,
    key: "Z01",
    showKey: "15"
  },
  {
    id: 2,
    label: "Program Management",
    link: "/program-management",
    icon: `${profit}`,
    key: "B02",
    showKey: "2",
    permission: Permissions.PROGRAM_MANAGEMENT_SCREEN,
  },
  {
    id: 3,
    label: "User Management",
    link: "/user-management",
    icon: `${group}`,
    key: "C03",
    showKey: "3",
    permission: Permissions.USER_MANAGEMENT,
    items: [
      {
        id: 4,
        label: "Group Management",
        link: "/group-management",
        icon: "",
        key: "C1",
        permission: Permissions.GROUP_MANAGEMENT_SCREEN,
      },
      {
        id: 5,
        label: "Role Management",
        link: "/roles-management",
        icon: "",
        key: "C2",
        permission: Permissions.ROLE_MANAGEMENT_SCREEN,
      },
      {
        id: 6,
        label: "Permission Management",
        link: "/permission-management",
        icon: "",
        key: "C3",
        permission: Permissions.PERMISSION_MANAGEMENT_SCREEN,
      },
      {
        id: 29,
        label: "Supervisor Management",
        link: "/supervisor-management",
        icon: "",
        key: "C90",
      },
    ],
  },
  {
    id: 7,
    label: "Organization Management",
    link: "/organization-management",
    icon: `${Group_management}`,
    key: "D04",
    showKey: "4",
    permission: Permissions.ORGANIZATION_MANAGEMENT,
    items: [
      {
        id: 18,
        label: "Site Management",
        link: "/site-management",
        icon: "",
        key: "D1",
        permission: Permissions.SITE_MANAGEMENT_SCREEN,
      },
      {
        id: 19,
        label: "Branch Management",
        link: "/branch-management",
        icon: "",
        key: "D2",
        permission: Permissions.BRANCH_MANAGEMENT_SCREEN,
      },
      {
        id: 20,
        label: "SKU Management",
        link: "/category-management",
        icon: "",
        key: "D3",
        permission: Permissions.CATEGORY_MANAGEMENT_SCREEN,
      },
    ],
  },
  {
    id: 8,
    label: "POP Management",
    link: "/pop-type-management",
    icon: `${pop_management}`,
    key: "E05",
    showKey: "5",
    permission: Permissions.POP_MANAGEMENT,
    items: [
      {
        id: 14,
        label: "POP Type",
        link: "/pop-types",
        icon: "",
        key: "E1",
        permission: Permissions.POP_TYPE_MANAGEMENT_SCREEN,
      },
      {
        id: 15,
        label: "POP Zone",
        link: "/pop-zone",
        icon: "",
        key: "E2",
        permission: Permissions.ZONE_MANAGEMENT_SCREEN,
      },
      {
        id: 16,
        label: "POP Region",
        link: "/pop-region",
        icon: "",
        key: "E3",
        permission: Permissions.REGION_MANAGEMENT_SCREEN,
      },
      {
        id: 17,
        label: "POP Territory",
        link: "/pop-territory",
        icon: "",
        key: "E4",
        permission: Permissions.TERRITORY_MANAGEMENT_SCREEN,
      },
    ],
  },

  {
    id: 21,
    label: "Reporting",
    link: "/reporting",
    icon: `${reporting}`,
    key: "G01",
    showKey: "7",
    permission: Permissions.REPORTING_MANAGEMENT,
    // items: [
    //   {
    //     id: 22,
    //     label: "Organization Admin",
    //     link: "/super-organization",
    //     icon: "",
    //     key: "G1",
    //   },
    //   {
    //     id: 23,
    //     label: "Organization Details ",
    //     link: "/super-organization-Details",
    //     icon: "",
    //     key: "G2",
    //   },
    // ],
  },
  {
    id: 30,
    label: "Finance Management",
    link: "/finance-management",
    icon: `${finance}`,
    key: "X01",
    showKey: "17",
    permission: Permissions.REPORTING_MANAGEMENT,
  },
  {
    id: 24,
    label: "Monitoring",
    link: "/monitoring",
    icon: `${eye_menu}`,
    key: "H01",
    showKey: "9",
    permission: Permissions.MONITORING_MANAGEMENT,
    items: [
      {
        id: 25,
        label: "Attendance",
        link: "/attendance",
        icon: "",
        key: "H1",
        permission: Permissions.ATTENDANCE_MANAGEMENT_SCREEN,
      },
    ],
  },
  {
    id: 10,
    label: "Geo Fence",
    link: "/geo-fence",
    icon: `${location}`,
    key: "F01",
    showKey: "6",
    permission: Permissions.GEO_FENCE_MANAGEMENT,
  },
  {
    id: 10,
    label: "Feature Management",
    link: "/feature-management",
    icon: `${feature}`,
    key: "F02",
    showKey: "11",
    permission: "",
  },
  {
    id: 24,
    label: "Speech Checking",
    link: "/speech-checking",
    icon: `${speaker}`,
    key: "F04",
    showKey: "8",
  },
  {
    id: 25,
    label: "Game Management",
    link: "/games",
    icon: `${game}`,
    key: "I01",
    showKey: "10",
  },
  {
    id: 26,
    label: "Announcement",
    link: "/announcement",
    icon: `${game}`,
    key: "J01",
    showKey: "12",
  },
];

export const breadCrumbsJson = [
  {
    path: "/pop-type-management",
    text: "Home > Point-of-Purchase Management",
    Links: "/main-dashboard/pop-type-management",
  },
  {
    path: "/dds-dashboard",
    text: "Dashboard > Analytics",
    Links: "/main-dashboard/dds-dashboard",
  },
  {
    path: "/feature-management",
    text: "Home > Feature Management",
    Links: "/main-dashboard/feature-management",
  },
  {
    path: "/finance-management",
    text: "Home > Finance Management",
    Links: "/main-dashboard/finance-management",
  },
  {
    path: "/pop-position",
    text: "Home > Point-of-Purchase Management > Management",
    Links: "/main-dashboard/pop-type-management/Point-of-Purchase",
  },
  {
    path: "/pop-types",
    text: "Home > Point-of-Purchase Management > Point-of-Purchase Type",
    Links: "/main-dashboard/pop-type-management/pop-types",
  },
  {
    path: "/pop-type-view",
    text: "Home > Point-of-Purchase Management > Management",
    Links: "/main-dashboard/pop-type-management/pop-types/pop-type-view",
  },
  {
    path: "/pop-zone",
    text: "Home > Point-of-Purchase Management > Point-of-Purchase Zone",
    Links: "/main-dashboard/pop-type-management/pop-zone",
  },
  {
    path: "/pop-region",
    text: "Home > Point-of-Purchase Management > Point-of-Purchase Region",
    Links: "/main-dashboard/pop-type-management/pop-region",
  },
  {
    path: "/pop-territory",
    text: "Home > Point-of-Purchase Management > Point-of-Purchase Territory",
    Links: "/main-dashboard/pop-type-management/pop-territory",
  },
  {
    path: "/program-management",
    text: "Home > Program Management",
    Links: "/main-dashboard/program-management",
  },
  {
    path: "/view-program-forms",
    text: "Home > Program Management > Activity Management > Program Forms",
    Links:
      "/main-dashboard/program-management/activities-management/view-program-forms",
  },

  {
    path: "/create-activity",
    text: "Home > Program Management > Create Activity",
    Links: "/main-dashboard/program-management/create-activity",
  },
  {
    path: "/create-game-activity",
    text: "Home > Program Management > Create Activity",
    Links: "/main-dashboard/program-management/create-game-activity",
  },
  {
    path: "/activities-management",
    text: "Home > Program Management > Activity Management",
    Links: "/main-dashboard/program-management/activities-management",
  },
  {
    path: "/activity-form-view",
    text: "Home > Program Management > Form Mobile View",
    Links: "/main-dashboard/program-management/activity-form-view",
  },
  {
    path: "/user-management",
    text: "Home > User Management  ",
    Links: "/main-dashboard/user-management",
  },
  {
    path: "/group-management",
    text: "Home > User Management > Group Management ",
    Links: "/main-dashboard/user-management/group-management",
  },
  {
    path: "/group-assignment",
    text: "Home > User Management > Group Management > Group Assignment ",
    Links: "/main-dashboard/user-management/group-management/group-assignment",
  },
  {
    path: "/branch-assignment",
    text: "Home > User Management > Group Management > Assign Branch Group ",
    Links: "/main-dashboard/user-management/group-management/branch-assignment",
  },
  {
    path: "/status-management",
    text: "Home > User Management > Group Management > Update Group ",
    Links: "/main-dashboard/user-management/group-management/status-management",
  },
  {
    path: "/roles-management",
    text: "Home > User Management > Role Management ",
    Links: "/main-dashboard/user-management/roles-management",
  },
  {
    path: "/assign-role",
    text: "Home > User Management > Role Management ",
    Links: "/main-dashboard/user-management/roles-management/assign-role",
  },
  {
    path: "/permission-management",
    text: "Home > User Management > Permission Management ",
    Links: "/main-dashboard/user-management/permission-management",
  },
  {
    path: "/organization-management",
    text: "Home > Organization Management ",
    Links: "/main-dashboard/organization-management",
  },
  {
    path: "/organization-details",
    text: "Home > Organization Management > Organization Details ",
    Links: "/main-dashboard/organization-management/organization-details",
  },
  {
    path: "/branch-details",
    text: "Home > Organization Management > Branch Details ",
    Links:
      "/main-dashboard/organization-management/organization-details/branch-details",
  },
  {
    path: "/group-users",
    text: "Home > Organization Management > Group Details ",
    Links:
      "/main-dashboard/organization-management/organization-details/branch-details/group-users",
  },
  {
    path: "/site-management",
    text: "Home > Organization Management > Site Management ",
    Links: "/main-dashboard/organization-management/site-management",
  },
  {
    path: "/branch-management",
    text: "Home > Organization Management > Branch Management ",
    Links: "/main-dashboard/organization-management/branch-management",
  },
  {
    path: "/category-management",
    text: "Home > Organization Management > SKU Management ",
    Links: "/main-dashboard/organization-management/category-management",
  },
  {
    path: "/subcategory-management",
    text: "Home > Organization Management > SKU Management > Sub Category Management ",
    Links: "/main-dashboard/organization-management/category-management",
  },
  {
    path: "/product-management",
    text: "Home > Organization Management > SKU Management > Sub Category Management > Product Management ",
    Links:
      "/main-dashboard/organization-management/category-management/product-management",
  },
  {
    path: "/geo-fence",
    text: "Home > Geo Fence ",
    Links: "/main-dashboard/geo-fence",
  },
  {
    path: "/reporting",
    text: "Home > Reporting ",
    Links: "/main-dashboard/reporting",
  },
  {
    path: "/monitoring",
    text: "Home > Monitoring ",
    Links: "/main-dashboard/monitoring",
  },
  {
    path: "/attendance",
    text: "Home > Monitoring > Attendance ",
    Links: "/main-dashboard/monitoring/monitoring-attendance",
  },
  {
    path: "/games",
    text: "Home > Games ",
    Links: "/main-dashboard/games",
  },
  {
    path: "/announcement",
    text: "Home > Announcement ",
    Links: "/main-dashboard/announcement",
  },
  {
    path: "/gifts",
    text: "Home > Game Management > Gifts Management",
    Links: "/main-dashboard/games/gifts",
  },
];
